import { useEffect, useState } from "react";
import ResultHighChart from "../Helper/Result-HighChart";
import bulbIcon from "../images/bulb-icon.png";
import thumbnail from "../images/thumb-icon.png";
import { PUBLIC_URL } from "../env.global";
import { useRef } from "react";
import { toPng } from "html-to-image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ApneaHeader,
  BurunoutHeader,
  PercentageColor,
  VatilityHeader,
} from "../Helper/Helper";
import { TextPercentageColor } from "../Helper/textHelper";
import { ResultPrecautions } from "./resultPrecautions";

export default function ResultPage() {
  const [quesPercentage, setQuesPercentage] = useState([]);
  const [perBarometer, setperBarometer] = useState();
  const [vitality, setVitality] = useState([]);
  const [burnout, setBurnout] = useState([]);
  const [apnea, setApnea] = useState([]);
  const [vMore, setVMore] = useState(false);
  const [vbuttonText, setVButtonText] = useState("More");
  const [bMore, setBMore] = useState(false);
  const [bbuttonText, setBButtonText] = useState("More");
  const [aMore, setAMore] = useState(false);
  const [abuttonText, setAButtonText] = useState("More");
  const [resultImage, setResultImage] = useState();
  const emailAddress = JSON.parse(localStorage.getItem("email"));
  const [showComp, setShowComp] = useState(true);

  const [vatilityP, setVatilityP] = useState({});
  const [burnoutP, setBurnoutP] = useState({});
  const [apneaP, setApneaP] = useState({});

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var apiCalled = localStorage.getItem("apiCalled");


  const elementRef = useRef(null);

  const htmlToImageConvert = () => {

    const element = elementRef?.current?.cloneNode(true);

    element
      .querySelectorAll("#resultQuestions")
      .forEach((x) => x.setAttribute("style", "display:none !important;"));

    element
      .querySelectorAll("#pdfQuestions")
      .forEach((x) => x.setAttribute("style", "display:block !important;"));
    element
      .querySelectorAll("#bottom-list-group")
      .forEach((x) => x.setAttribute("style", "padding: '0px !important;"));

    toPng(elementRef?.current, { cacheBust: false }).then((dataUrl) => {
      setResultImage(dataUrl);

    });
    if (resultImage) {
      sendEmail();
    }

  };

  document.querySelector(".example");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const quesPercentageApi = async () => {

    try {
      const response = await fetch(
        PUBLIC_URL +
        `Question/GetQuestionPercentageByValue?EmailAddress=${emailAddress}`,
        requestOptions
      );
      const data = await response.json();
      if (data?.statusCode === 200) {        
        setQuesPercentage(data?.data?.data);
        localStorage.setItem("questionPercentage", JSON.stringify(data?.data?.data));
        localStorage.setItem("perBarometer", JSON.stringify(data.data.getPersentage));

        await new Promise(resolve => setTimeout(resolve, 2000));
        htmlToImageConvert();
      }
    } catch (error) {
      console.log(error);
    }
  };

  var rawSendEmail = JSON.stringify({
    toEmail: emailAddress,
    image: resultImage,
  });

  var sendEmailrequest = {
    method: "Post",
    headers: myHeaders,
    body: rawSendEmail,
  };

  const sendEmail = () => {
    fetch(PUBLIC_URL + "Question/SendEmail", sendEmailrequest)
      .then((res) => res.json())
      .then((response) => {
        if (response.isSuccess) {
          toast.success(response.message);
          localStorage.setItem("apiCalled", JSON.stringify("true"));
        } else if (!response.isSuccess) {
          toast.error(response.message);
        }
        setShowComp(false);
      })
      .catch((error) => {
        console.log(error)
        setShowComp(false);
      }
      );

  };

  useEffect(() => {
    if (!apiCalled) {
      quesPercentageApi();
    } else {
      setShowComp(false);
    }
  }, [resultImage]);

  useEffect(() => {
    if (quesPercentage) {
      const questionsData = localStorage.getItem("questionPercentage")
      const barometerData = localStorage.getItem("perBarometer");
      if (questionsData && barometerData) {

        const quesPercentageData = JSON.parse(questionsData);

        let vitalityData = [];
        let BurnoutData = [];
        let ApneaData = [];

        const perBarometerData = JSON.parse(barometerData);
        setperBarometer(perBarometerData);
        let vHeader = VatilityHeader(perBarometerData[0]?.VitalityPercentage);
        setVatilityP(vHeader);
        let bHeader = BurunoutHeader(perBarometerData[1]?.BurnoutPercentage);
        setBurnoutP(bHeader);
        let aHeader = ApneaHeader(perBarometerData[2]?.ApneaPercentage);
        setApneaP(aHeader);
        if (quesPercentageData?.length) {
          quesPercentageData?.map((item, index) => {
            if (item.questionData.typeID.includes("1")) {
              vitalityData.push(item);
            }
            if (item.questionData.typeID.includes("2")) {
              BurnoutData.push(item);
            }
            if (item.questionData.typeID.includes("3")) {
              ApneaData.push(item);
            }
          });
          setApnea(ApneaData);
          setBurnout(BurnoutData);
          setVitality(vitalityData);
        }
      } 
    }
  }, [quesPercentage]);

  const newvitalityData = vitality.slice(0, 2);
  const newBurnoutData = burnout.slice(0, 2);
  const newApneaData = apnea.slice(0, 2);

  const VatilityhandleClick = () => {
    if (vMore) {
      setVButtonText("More");
    } else {
      setVButtonText("Show Less");
    }
    setVMore(!vMore);
  };

  const BurnouthandleClick = () => {
    if (bMore) {
      setBButtonText("More");
    } else {
      setBButtonText("Show Less");
    }
    setBMore(!bMore);
  };

  const ApneahandleClick = () => {
    if (aMore) {
      setAButtonText("More");
    } else {
      setAButtonText("Show Less");
    }
    setAMore(!aMore);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <>
      <div
        className="loader-resultPage"
        id="backdrop"
        style={{ display: showComp ? "block" : "none", zIndex: "1" }}
      >
        <span
          class="loader"
          style={{ display: showComp ? "block" : "none", zIndex: "1" }}
        ></span>
      </div>

      <div>
        <div className="container">
          {/* <ToastContainer theme="colored" /> */}
          <div ref={elementRef}>
            <div className="row">
              {/* Vatility graph  */}
              <div className="col-sm-4 pt-2">
                <div className="card">
                  <div className="card-header text-center"> Vitality</div>
                  <div className="card-body" style={{ height: "245px" }}>
                    <ResultHighChart
                      perBarometer={perBarometer ? perBarometer[0]?.VitalityPercentage : 0}
                      id={1}
                      title="Vitality"
                    />
                  </div>
                  <ResultPrecautions perBarometer={vatilityP} />

                  <div className="bg-blue p-2 mb-1">
                    <p className="card-text text-uppercase ">
                      Scoring related to
                      <br />
                      <strong> Vitality questions</strong>
                    </p>
                  </div>

                  <div style={{ display: "none" }} id="resultQuestions">
                    <div className=" resultQuestions pt-0 pr-3 pb-3 pl-3">
                      {!vMore ? (
                        <>
                          {" "}
                          {newvitalityData?.map((item, index) => {
                            return (
                              <>
                                <p className="mb-2">
                                  {item.questionData.questionText}
                                </p>

                                <div className="progress ">
                                  <div
                                    className="progress-bar "
                                    style={{
                                      color: TextPercentageColor(
                                        item.percentageValue
                                      ),
                                      width: item.percentageValue + "%",
                                      backgroundColor: PercentageColor(
                                        item.percentageValue
                                      ),
                                    }}
                                  >
                                    {item.percentageValue + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {vitality?.map((item, index) => {
                            return (
                              <>
                                <p className="mb-2">
                                  {item.questionData.questionText}
                                </p>

                                <div className="progress ">
                                  <div
                                    className="progress-bar "
                                    style={{
                                      color: TextPercentageColor(
                                        item.percentageValue
                                      ),
                                      width: item.percentageValue + "%",
                                      backgroundColor: PercentageColor(
                                        item.percentageValue
                                      ),
                                    }}
                                  >
                                    {item.percentageValue + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                    {vitality?.length > 2 ? (
                      <div className="col text-center pb-3">
                        <button
                          onClick={() => VatilityhandleClick()}
                          type="button"
                          className="btn btn-success btn-sm"
                        >
                          {vbuttonText}{" "}
                          <i className="fa fa-arrow-circle-o-right "></i>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className=" pt-0 pr-3 pb-3 pl-3"
                    id="pdfQuestions"
                    style={{ display: "block" }}
                  >
                    {vitality?.map((item, index) => {
                      return (
                        <>
                          <p className="mb-2">
                            {item.questionData.questionText}
                          </p>
                          <div className="progress ">
                            <div
                              className="progress-bar "
                              style={{
                                color: TextPercentageColor(
                                  item.percentageValue
                                ),
                                width: item.percentageValue + "%",
                                backgroundColor: PercentageColor(
                                  item.percentageValue
                                ),
                              }}
                            >
                              {item.percentageValue + "%"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* End Vatility graph  */}

              {/* Burnout graph  */}
              <div className="col-sm-4 pt-2">
                <div className="card">
                  <div className="card-header text-center">
                    {" "}
                    Burnout Inventory
                  </div>
                  <div className="card-body" style={{ height: "245px" }}>
                    <ResultHighChart
                      id={2}
                      perBarometer={perBarometer ? perBarometer[1]?.BurnoutPercentage : 0}
                      title="Burnout Inventory"
                    />
                  </div>

                  <ResultPrecautions perBarometer={burnoutP} />

                  <div className="bg-blue p-2  mb-1">
                    <p className="card-text text-uppercase ">
                      Scoring related to <br />
                      <strong> Burnout questions</strong>
                    </p>
                  </div>

                  <div style={{ display: "none" }} id="resultQuestions">
                    <div className=" resultQuestions pt-0 pr-3 pb-3 pl-3">
                      {!bMore ? (
                        <>
                          {newBurnoutData?.map((item, index) => {
                            return (
                              <>
                                <p className="mb-2">
                                  {item.questionData.questionText}
                                </p>

                                <div className="progress ">
                                  <div
                                    className={"progress-bar "}
                                    style={{
                                      color: TextPercentageColor(
                                        item.percentageValue
                                      ),
                                      width: item.percentageValue + "%",
                                      backgroundColor: PercentageColor(
                                        item.percentageValue
                                      ),
                                    }}
                                  >
                                    {item.percentageValue + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {burnout?.map((item, index) => {
                            return (
                              <>
                                <p className="mb-2">
                                  {item.questionData.questionText}
                                </p>

                                <div className="progress ">
                                  <div
                                    className="progress-bar "
                                    style={{
                                      color: TextPercentageColor(
                                        item.percentageValue
                                      ),
                                      width: item.percentageValue + "%",
                                      backgroundColor: PercentageColor(
                                        item.percentageValue
                                      ),
                                    }}
                                  >
                                    {item.percentageValue + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                    {burnout?.length > 2 ? (
                      <div className="col text-center pb-3">
                        <button
                          onClick={() => BurnouthandleClick()}
                          type="button"
                          className="btn btn-success btn-sm"
                        >
                          {bbuttonText}{" "}
                          <i className="fa fa-arrow-circle-o-right "></i>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className=" pt-0 pr-3 pb-3 pl-3"
                    id="pdfQuestions"
                    style={{ display: "block" }}
                  >
                    {burnout?.map((item, index) => {
                      return (
                        <>
                          <p className="mb-2">
                            {item.questionData.questionText}
                          </p>

                          <div className="progress ">
                            <div
                              className="progress-bar "
                              style={{
                                color: TextPercentageColor(
                                  item.percentageValue
                                ),
                                width: item.percentageValue + "%",
                                backgroundColor: PercentageColor(
                                  item.percentageValue
                                ),
                              }}
                            >
                              {item.percentageValue + "%"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* End Burnout graph  */}

              {/* Apnea graph  */}
              <div className="col-sm-4 pt-2">
                <div className="card">
                  <div className="card-header text-center"> Apnea</div>
                  <div className="card-body" style={{ height: "245px" }}>
                    <ResultHighChart
                      id={3}
                      perBarometer={perBarometer ? perBarometer[2]?.ApneaPercentage : 0}
                      title="Apnea"
                    />
                  </div>

                  <ResultPrecautions perBarometer={apneaP} />

                  <div className="bg-blue p-2  mb-1">
                    <p className="card-text text-uppercase ">
                      Scoring related to <br />
                      <strong>Apnea questions</strong>
                    </p>
                  </div>

                  <div style={{ display: "none" }} id="resultQuestions">
                    <div className="resultQuestions pt-0 pr-3 pb-3 pl-3">
                      {!aMore ? (
                        <>
                          {newApneaData?.map((item, index) => {
                            return (
                              <>
                                <p className="mb-2">
                                  {item.questionData.questionText}
                                </p>

                                <div className="progress ">
                                  <div
                                    className="progress-bar  "
                                    style={{
                                      color: TextPercentageColor(
                                        item.percentageValue
                                      ),
                                      width: item.percentageValue + "%",
                                      backgroundColor: PercentageColor(
                                        item.percentageValue
                                      ),
                                    }}
                                  >
                                    {item.percentageValue + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {apnea?.map((item, index) => {
                            return (
                              <>
                                <p className="mb-2">
                                  {item.questionData.questionText}
                                </p>

                                <div className="progress ">
                                  <div
                                    className="progress-bar "
                                    style={{
                                      color: TextPercentageColor(
                                        item.percentageValue
                                      ),
                                      width: item.percentageValue + "%",
                                      backgroundColor: PercentageColor(
                                        item.percentageValue
                                      ),
                                    }}
                                  >
                                    {item.percentageValue + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                    {apnea?.length > 2 ? (
                      <div className="col text-center pb-3">
                        <button
                          onClick={() => ApneahandleClick()}
                          type="button"
                          className="btn btn-success btn-sm"
                        >
                          {abuttonText}{" "}
                          <i className="fa fa-arrow-circle-o-right "></i>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className=" pt-0 pr-3 pb-3 pl-3"
                    id="pdfQuestions"
                    style={{ display: "block" }}
                  >
                    {apnea?.map((item, index) => {
                      return (
                        <>
                          <p className="mb-2">
                            {item.questionData.questionText}
                          </p>

                          <div className="progress ">
                            <div
                              className="progress-bar "
                              style={{
                                color: TextPercentageColor(
                                  item.percentageValue
                                ),
                                width: item.percentageValue + "%",
                                backgroundColor: PercentageColor(
                                  item.percentageValue
                                ),
                              }}
                            >
                              {item.percentageValue + "%"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* End Apnea graph  */}
            </div>

            <div className="pt-3">
              <ul className="list-group list-group-horizontal">
                <li
                  className="list-group-item btm-list"
                  id="bottom-list-group"
                  style={{ width: "350px" }}
                >
                  <i className="fa fa-circle fa-2x dgc"></i> It looks like you
                  are doing very good.
                </li>
                <li
                  className="list-group-item btm-list"
                  id="bottom-list-group"
                  style={{ width: "200px" }}
                >
                  <i className="fa fa-circle fa-2x gc"></i> Doing Good
                </li>
                <li
                  className="list-group-item btm-list"
                  id="bottom-list-group"
                  style={{ width: "250px" }}
                >
                  <i className="fa fa-circle fa-2x yc"></i> To be Monitored
                </li>
              </ul>
            </div>

            <div className="">
              <ul className="list-group list-group-horizontal">
                <li
                  className="list-group-item btm-list"
                  id="bottom-list-group"
                  style={{ width: "360px" }}
                >
                  <i className="fa fa-circle fa-2x lr"></i> Need your attention
                  for mprovements
                </li>
                <li
                  className="list-group-item btm-list"
                  id="bottom-list-group"
                  style={{ width: "250px" }}
                >
                  <i className="fa fa-circle fa-2x dr"></i> Indication of an
                  Early Risk
                </li>
                <li
                  className="list-group-item btm-list"
                  id="bottom-list-group"
                  style={{ width: "350px" }}
                >
                  <i className="fa fa-circle fa-2x vdr"></i> Indication of a
                  Potential High Risk
                </li>
              </ul>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
