import { Link, useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "../env.global";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";

export default function Submission() {
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState();
  const [otperror, setOtpError] = useState();
  const [showotp, setShowotp] = useState(false);
  // const token = JSON.parse(localStorage.getItem("token"));
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const textRef = useRef()

  var optionsData = JSON.parse(localStorage.getItem("questionAwns"));
  localStorage.setItem("email", JSON.stringify(email));
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", `Bearer ${token}`);

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      return true;
    } else {
      return false;
    }
  };

  var raw = JSON.stringify({
    emailAddress: email,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  useEffect(() => {}, [email, emailError, otp, otperror, optionsData]);

  const sendOtp = () => {
    
    if (email) {
      if (validateEmail()) {
        setShowotp(true);
        if (optionsData.length) {
          setEmailError("");
          fetch(PUBLIC_URL + "Auth/sendOtp", requestOptions)
            .then((res) => res.json())
            .then((response) => {
              if (response.isSuccess) {
                toast.success(response.message);
              } else if (!response.isSuccess) {
                toast.error(response.message);
              }
            })
            .catch((error) => console.log(error));
        } else {
          setShowotp(false);
        }
      } else {
        setEmailError("Please enter valid email !");
        setShowotp(false);
      }
    }
    
  };

  var rawAnsSheet = JSON.stringify({
    emailAddress: email,
    otp: otp,
    answerSheetRequestModel: optionsData,
  });

  var addAnswerrequestOptions = {
    method: "POST",
    headers: myHeaders,
    body: rawAnsSheet,
  };

  const addAnswerSheet = () => {
    if (!otp) {
      setOtpError("Please enter your OTP");
    } else if (otp) {
      setOtpError("");
      fetch(PUBLIC_URL + "Question/AddAnswerSheet", addAnswerrequestOptions)
        .then((res) => res.json())
        .then((response) => {
          if (response.isSuccess) {
            toast.success(response.message);
            navigate("/result");
          } else if (!response.isSuccess) {
            toast.error(response.message);
          }
        })
        .catch((error) => toast.error(error));
    }
  };

  // const otpButtonRef = useRef(null);
  
  useEffect(() => {
    if (showotp) {
      if (textRef.current) {
        window.scrollTo(0, textRef.current.offsetTop);
      }
    }
  }, [showotp]);

  


  return (
    <>
      <div className="container">
        <p className="pt-4 text">
          <span className="blue-text font-weight-bold ">
            This ARA Self-Test
          </span>
          &nbsp; uses an informal approach to test if you have indications of
          Over-Effort-Syndrome risks? While it may be intuitively useful, it has
          not been validated through controlled scientific tests and must
          therefore not be used as a diagnostic technique. Please, therefore,
          interpret the results with common sense. <br />
          <br />
          You gives ARA.Health allowance to send limited number of mails
          regarding related program offerings.
        </p>
        <div className="feedback" style={{ paddingBottom: "15px" }}>
          <div className="row">
            <div className="col-sm-9">
              <form action="#">
                <div className="form-group" style={{ marginBottom: "0" }}>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    id="email"
                    value={email}
                  />
                  <p style={{ marginBottom: "0", color: "red" }}>
                    {emailError}
                  </p>
                </div>
              </form>
            </div>
            <div className="col-sm-3">
              <Link to="">
                <button
                  disabled={!email ? true : false}
                  onClick={() => sendOtp()}
                  className="btn btn-success rounded-pill btn-lg btn-block text-uppercase OTP"
                >
                  {showotp ? "Resend OTP" : "Get your feedback"}
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* OTP HTML start from here */}

        {showotp ? (
          <div
            className="feedback"
            style={{ paddingBottom: "15px", marginTop: "1.5rem" }}
          >
            <div className="row" >
              <div className="col-sm-9">
                <form action="#">
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <input
                      autoFocus 
                      onChange={(e) => setOtp(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                      id="otp"
                      value={otp}
                    />
                    <p style={{ marginBottom: "0", color: "red" }}>
                      {otperror}
                    </p>
                  </div>
                </form>
              </div>
              <div className="col-sm-3">
                <Link to="">
                  <button
                    onClick={() => addAnswerSheet()}
                    className="btn btn-success rounded-pill btn-lg btn-block text-uppercase OTP"
                  >
                    Verify
                  </button>
                </Link>
              </div>
            </div>
            
          </div>
        ) : (
          ""
        )}
      </div>
      <div ref={textRef}></div> 
    </>
  );
}
