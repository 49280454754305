import { Link } from "react-router-dom";
import { PUBLIC_URL } from "../env.global";
import { useEffect, useRef, useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Submission from "./Submission";
import { Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Questions() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [queData, setQueData] = useState([]);
  const [optionID, setOptionID] = useState([]);
  const [questionAwns, setQuestionAwns] = useState([]);
  const [submissionPage, setSubmissionPage] = useState(false);
  const [button, setButton] = useState(false);
  var optionsData = JSON.parse(localStorage.getItem("questionAwns"));
  const textRef = useRef();
  const itemsPerPage = 2;
  // const token = JSON.parse(localStorage.getItem("token"));
  let pageStartIndex = 0;
  let pageEndIndex = 2;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", `Bearer ${token}`);

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    backgroundColor: "#bec6cd",
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#000",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "25px",
      height: "25px",
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  });

  var requestOptions = {
    method: "GET",
  };

  const QuestionsAndOption = () => {
    fetch(PUBLIC_URL + "Question/GetQuestionAndOptions", requestOptions)
      .then((res) => res.json())
      .then((response) => {
        let questionsData = response.data;
        if (questionsData.length) {
          questionsData.map((question, qindex) => {
            question.options.map((option, oindex) => {
              option["isSelected"] = false;
            });
          });
        }
        setData(questionsData);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    QuestionsAndOption();
    localStorage.clear();
  }, []);

  const checkQuestion = () => {
    if (page === 9 && optionsData.length) {
      setSubmissionPage(true);
      setButton(true);
    } else if (page === 9 && !optionsData.length) {
      toast.error(
        "you have not selected any question, please select any question first"
      );
      setButton(true);
    }
  };

  const previous = () => {
    window.parent.postMessage("scrollToTop", "*");

    setPage(page - 1);
    scrollOnTop()
  };
  const next = () => {
   window.parent.postMessage("scrollToTop", "*");

    setPage(page + 1);
    checkQuestion();
    scrollOnTop()
  };

  useEffect(() => {
    const displayedData = data?.slice(pageStartIndex, pageEndIndex);
    setQueData(displayedData);
  }, [data, pageStartIndex, pageEndIndex]);

  useEffect(() => {
    const pageStartIndex = (page - 1) * itemsPerPage;
    const pageEndIndex = pageStartIndex + itemsPerPage;
    if (page !== 10) {
      const displayedData = data.slice(pageStartIndex, pageEndIndex);
      setQueData(displayedData);
    }
  }, [page]);

  const setOptionValue = (e, optionId, questionId) => {
    setOptionID(e.target.value);
    let selectedValues = questionAwns;

    let sDataIndex = selectedValues?.findIndex(
      (x) => x?.questionID === questionId
    );
    
    if (sDataIndex >= 0) {
      selectedValues[sDataIndex]["optionID"] = optionId;
      setQuestionAwns([...selectedValues]);
    } else {
      setQuestionAwns([
        ...selectedValues,
        { questionID: questionId, optionID: optionId },
      ]);
    }

    let oldData = data;
    let questionIndex = data?.findIndex((x) => x?.id === questionId);
    let questionData = data?.find((x) => x?.id === questionId);
    if (questionData) {
      questionData?.options?.map((option, index) => {
        option.isSelected = false;
      });

      let findOption = questionData?.options?.find((x) => x?.id === optionId);
      findOption["isSelected"] = true;

      oldData[questionIndex] = questionData;
      setData(oldData);
    }
  };

  useEffect(() => {
    localStorage.setItem("questionAwns", JSON.stringify(questionAwns));
    if (page < 10) {
      setButton(false);
    }
  }, [optionsData, submissionPage]);

  const scrollOnTop = () => {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // })
    if (textRef.current) {
      window.scrollTo(0, textRef.current.offsetTop);
    }
  };

  const BpRadio = ({ option, item }) => { 
    return (
      <Radio
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        checked={option?.isSelected}
        value={optionID}
        onChange={(e) => setOptionValue(e, option.id, item.id)}
        className="options"
        style={{
          background: "none",
          fontFamily: "PT Sans, sans-serif !important",
        }}
      />
    );
  };
console.log(queData,"queData")
  return (
    <>
      {queData?.length ? (
        <div className="container" style={{ paddingBottom: "1.5rem" }}>
          <h3 ref={textRef} className="text-center text-uppercase pt-5 pb-5">
            <strong>ARA Self-test</strong>
          </h3>
          <FormControl style={{ width: "100%" }}>
            {queData?.map((item, qindex) => {
              return (
                <>
                  <div className="row pt-3 ml-0 " key={item.id}>
                    <div className="col h5 pl-0">
                      <b>
                        {item.id}. {item.questionText}
                      </b>
                    </div>
                  </div>
                  <hr
                    style={{ borderTop: "1px solid #000 !important" }}
                    className="p-0"
                  />
                  <RadioGroup
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                    className="list-group list-group-flus"
                  >
                    {item.description ? (
                      <p className="list-group-item mb-0">
                        <b class="options p-0 ">
                          <i>{item.description}</i>
                        </b>
                      </p>
                    ) : (
                      ""
                    )}

                    {item?.options?.map((option) => {
                      return (
                        <>
                          <FormControlLabel
                            value={option.optionText}
                            control={<BpRadio option={option} item={item} />}
                            label={option.optionText}
                            className="list-group-item m-0"
                          />
                        </>
                      );
                    })}
                  </RadioGroup>
                </>
              );
            })}
          </FormControl>

          <div className="col-sm-12 text-center py-5">
            <Link style={{ marginRight: "10px" }}>
              <button
                style={{ background: "#ccc" }}
                disabled={page === 1 ? true : false}
                onClick={previous}
                className="btn btn-light rounded-pill"
              >
                <span className="pl-4 pr-4">Previous</span>
              </button>
            </Link>
            <Link>
              <button
                disabled={button}
                onClick={next}
                className="btn btn-success rounded-pill"
              >
                <span className="pl-4 pr-4">Next</span>
              </button>
            </Link>
          </div>

          {questionAwns.length && page > 9 ? (
            <>
              <Submission questionAwns={questionAwns} />
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <>
          <span class="loader"></span>
        </>
      )}
    </>
  );
}
