import { Apnea_Header_100, Apnea_Header_24_1, Apnea_Header_49_25, Apnea_Header_62_50, Apnea_Header_74_63, Apnea_Header_99_75, Apnea_Recommendation_100, Apnea_Recommendation_24_1, Apnea_Recommendation_49_25, Apnea_Recommendation_62_50, Apnea_Recommendation_74_63, Apnea_Recommendation_99_75, Apnea_Suggestion_100, Apnea_Suggestion_24_1, Apnea_Suggestion_49_25, Apnea_Suggestion_62_50, Apnea_Suggestion_74_63, Apnea_Suggestion_99_75, Burnout_Header_100, Burnout_Header_24_1, Burnout_Header_49_25, Burnout_Header_62_50, Burnout_Header_74_63, Burnout_Header_99_75, Burnout_Recommendation_100, Burnout_Recommendation_24_1, Burnout_Recommendation_49_25, Burnout_Recommendation_62_50, Burnout_Recommendation_74_63, Burnout_Recommendation_99_75, Burnout_Suggestion_100, Burnout_Suggestion_24_1, Burnout_Suggestion_49_25, Burnout_Suggestion_62_50, Burnout_Suggestion_74_63, Burnout_Suggestion_99_75, vitality_Header_100, vitality_Header_24_1, vitality_Header_49_25, vitality_Header_62_50, vitality_Header_74_63, vitality_Header_99_75, vitality_Recommendation_100, vitality_Recommendation_24_1, vitality_Recommendation_49_25, vitality_Recommendation_62_50, vitality_Recommendation_74_63, vitality_Recommendation_99_75, vitality_Suggestion_100, vitality_Suggestion_24_1, vitality_Suggestion_49_25, vitality_Suggestion_62_50, vitality_Suggestion_74_63, vitality_Suggestion_99_75 } from "./constantMessage";

export const PercentageColor = (percentage) => {
  let componentToRender;
  let textColor;

  switch (true) {
    case percentage === 100:
      componentToRender = '#70ad47';
      textColor = '#fff'
      break;
    case percentage >= 75 && percentage <= 99:
      componentToRender = '#92d050';
      textColor = '#fff'
      break;
    case percentage >= 63 && percentage <= 74:
      componentToRender = '#ffff0f';
      textColor = '#fff'
      break;
    case percentage >= 50 && percentage <= 62:
      componentToRender = '#fff3cc';
      textColor = '#000'
      break;
    case percentage >= 25 && percentage <= 49:
      componentToRender = '#ff0015';
      textColor = '#fff'
      break;
    case percentage >= 1 && percentage <= 24:
      componentToRender = '#c01511';
      textColor = '#fff'
      break;
    default:
      componentToRender = '#c01511';
      textColor = '#fff'
  }

  return componentToRender;
};

export const VatilityHeader = (percentage) => {
  let componentToRender ={
    Header:'',
    Suggestions:'',
    Recomendation:''
  };
  
  switch (true) {
    case percentage === 100:
      componentToRender.Header= vitality_Header_100;
      componentToRender.Suggestions= vitality_Suggestion_100;
      componentToRender.Recomendation= vitality_Recommendation_100;
      
      break;
    case percentage >= 75 && percentage <= 99:
      componentToRender.Header= vitality_Header_99_75;
      componentToRender.Suggestions= vitality_Suggestion_99_75;
      componentToRender.Recomendation= vitality_Recommendation_99_75;
      break;
    case percentage >= 63 && percentage <= 74:
      componentToRender.Header= vitality_Header_74_63;
      componentToRender.Suggestions= vitality_Suggestion_74_63;
      componentToRender.Recomendation= vitality_Recommendation_74_63;
      break;
    case percentage >= 50 && percentage <= 62:
      componentToRender.Header= vitality_Header_62_50;
      componentToRender.Suggestions= vitality_Suggestion_62_50;
      componentToRender.Recomendation= vitality_Recommendation_62_50;
      break;
    case percentage >= 25 && percentage <= 49:
      componentToRender.Header= vitality_Header_49_25;
      componentToRender.Suggestions= vitality_Suggestion_49_25;
      componentToRender.Recomendation= vitality_Recommendation_49_25;
      break;
    case percentage >= 1 && percentage <= 24:
      componentToRender.Header= vitality_Header_24_1;
      componentToRender.Suggestions= vitality_Suggestion_24_1;
      componentToRender.Recomendation= vitality_Recommendation_24_1;
      break;
    default:
      componentToRender.Header= vitality_Header_24_1;
      componentToRender.Suggestions= vitality_Suggestion_24_1;
      componentToRender.Recomendation= vitality_Recommendation_24_1;
     
  }

  return componentToRender;
};

export const BurunoutHeader = (percentage) => {
  let componentToRender ={
    Header:'',
    Suggestions:'',
    Recomendation:''
  };
  
  switch (true) {
    case percentage === 100:
      componentToRender.Header= Burnout_Header_100;
      componentToRender.Suggestions= Burnout_Suggestion_100;
      componentToRender.Recomendation= Burnout_Recommendation_100;
      
      break;
    case percentage >= 75 && percentage <= 99:
      componentToRender.Header= Burnout_Header_99_75;
      componentToRender.Suggestions= Burnout_Suggestion_99_75;
      componentToRender.Recomendation= Burnout_Recommendation_99_75;
      break;
    case percentage >= 63 && percentage <= 74:
      componentToRender.Header= Burnout_Header_74_63;
      componentToRender.Suggestions= Burnout_Suggestion_74_63;
      componentToRender.Recomendation= Burnout_Recommendation_74_63;
      break;
    case percentage >= 50 && percentage <= 62:
      componentToRender.Header= Burnout_Header_62_50;
      componentToRender.Suggestions= Burnout_Suggestion_62_50;
      componentToRender.Recomendation= Burnout_Recommendation_62_50;
      break;
    case percentage >= 25 && percentage <= 49:
      componentToRender.Header= Burnout_Header_49_25;
      componentToRender.Suggestions= Burnout_Suggestion_49_25;
      componentToRender.Recomendation= Burnout_Recommendation_49_25;
      break;
    case percentage >= 1 && percentage <= 24:
      componentToRender.Header= Burnout_Header_24_1;
      componentToRender.Suggestions= Burnout_Suggestion_24_1;
      componentToRender.Recomendation= Burnout_Recommendation_24_1;
      break;
    default:
      componentToRender.Header= Burnout_Header_24_1;
      componentToRender.Suggestions= Burnout_Suggestion_24_1;
      componentToRender.Recomendation= Burnout_Recommendation_24_1;
     
  }

  return componentToRender;
};

export const ApneaHeader = (percentage) => {
  let componentToRender ={
    Header:'',
    Suggestions:'',
    Recomendation:''
  };
  
  switch (true) {
    case percentage === 100:
      componentToRender.Header= Apnea_Header_100;
      componentToRender.Suggestions= Apnea_Suggestion_100;
      componentToRender.Recomendation= Apnea_Recommendation_100;
      
      break;
    case percentage >= 75 && percentage <= 99:
      componentToRender.Header= Apnea_Header_99_75;
      componentToRender.Suggestions= Apnea_Suggestion_99_75;
      componentToRender.Recomendation= Apnea_Recommendation_99_75;
      break;
    case percentage >= 63 && percentage <= 74:
      componentToRender.Header= Apnea_Header_74_63;
      componentToRender.Suggestions= Apnea_Suggestion_74_63;
      componentToRender.Recomendation= Apnea_Recommendation_74_63;
      break;
    case percentage >= 50 && percentage <= 62:
      componentToRender.Header= Apnea_Header_62_50;
      componentToRender.Suggestions= Apnea_Suggestion_62_50;
      componentToRender.Recomendation= Apnea_Recommendation_62_50;
      break;
    case percentage >= 25 && percentage <= 49:
      componentToRender.Header= Apnea_Header_49_25;
      componentToRender.Suggestions= Apnea_Suggestion_49_25;
      componentToRender.Recomendation= Apnea_Recommendation_49_25;
      break;
    case percentage >= 1 && percentage <= 24:
      componentToRender.Header= Apnea_Header_24_1;
      componentToRender.Suggestions= Apnea_Suggestion_24_1;
      componentToRender.Recomendation= Apnea_Recommendation_24_1;
      break;
    default:
      componentToRender.Header= Apnea_Header_24_1;
      componentToRender.Suggestions= Apnea_Suggestion_24_1;
      componentToRender.Recomendation= Apnea_Recommendation_24_1;
     
  }

  return componentToRender;
};


