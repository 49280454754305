import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

const ResultHighChart = ({ id, perBarometer, title }) => {
  useEffect(() => {
    const options = {
      chart: {
        type: "gauge",
      },
      title: {
        text: "Chart",
        align: "center",
        verticalAlign: "top",
        y: 40,
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        enabled: false,
      },
      pane: {
        center: ["50%", "75%"],
        size: "50%",
        startAngle: -90,
        endAngle: 90,
        background: {
          borderWidth: 0,
          backgroundColor: "none",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      yAxis: [
        {
          lineWidth: 1,
          min: 0,
          max: 90,
          minorTickLength: 6,
          tickLength: 1,
          tickWidth: 5,
          labels: {
            enabled: false,
          },
          title: {
            text: "",
            useHTML: true,
            y: 80,
          },
          plotBands: [
            {
              from: 0,
              to: 99,
              color: "#b2acac33",
              innerRadius: "100%",
              outerRadius: "0%",
            },
          ],
          pane: 0,
        },
      ],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
              textShadow: "0px 1px 1px black",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "78%"],
        },
        gauge: {
          dataLabels: {
            enabled: true,
            border: "none",
          },
          dial: {
            radius: "80%",
          },
        },
      },
      series: [
        {
          type: "pie",
          name: title,
          innerSize: "60%",
          colors: ['#c01511', '#ff0015','#fff3cc', '#ffff0f','#92d050', '#70ad47'],
          data: [
            ["Indication of a Potential High Risk", 24],
            ["Indication of an Early Risk", 24],
            ["Need your attention for mprovements", 12],
            ["To be Monitored", 11],
            ["Doing Good", 24],
            ["It looks like you are doing very good.", 1+4],
          ],
        },
        {
          type: "gauge",
          data: [perBarometer>90?perBarometer-10:perBarometer],
          fontSize: 20,
          dial: {
            backgroundColor: "red",
            color: "red",
            radius: "70%",
            baseWidth: 7,
            baseLength: "5%",
            rearLength: "0%",
            fontSize: 20,
          },
          pivot: {
            backgroundColor: "red",
          },
          dataLabels: {
            style: {
              fontSize: 20,
              fontWeight: "normal",
              stroke: "none !important",
            },
            formatter: function () {
              return perBarometer+" %";
            },
          },
        },
      ],
    };

    // Render the chart
    Highcharts.chart("chart-container" + id, options);
  }, [perBarometer, title ]);
  
  return (
    <div id={"chart-container" + id} className="container-chart">
      {/* This div will be used as the chart container */}
    </div>
  );
};
export default ResultHighChart;
