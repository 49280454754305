import "./App.css";
import Home from "./home/home";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <Home/>
      <ToastContainer theme="colored" position="bottom-right" />
    </div>
  );
}

export default App;
