import { useEffect, useState } from "react";
import bulbIcon from "../images/bulb-icon.png";
import thumbnail from "../images/thumb-icon.png";

export const ResultPrecautions = ({ perBarometer }) => {
  const [dataText, setDataText] = useState({});
  useEffect(() => {
    setDataText(perBarometer);
  }, [perBarometer]);

  return (
    <>
      <div style={{ minHeight: "400px" }}>
        <div className="bg-light p-2 mb-1">
          <p className="card-text font-weight-bold">{dataText?.Header}</p>
        </div>
        <div className="bg-light p-2  mb-1">
          <div className="media">
            <img src={bulbIcon} className="mr-3" alt="..." />
            <div className="media-body">
              <strong> Suggestion :</strong> {dataText?.Suggestions}
            </div>
          </div>
        </div>
        <div className="bg-light p-2  mb-1">
          <div className="media">
            <img src={thumbnail} className="mr-3" alt="..." />
            <div className="media-body">
              <strong> Recommendation :</strong> {dataText?.Recomendation}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
