export const TextPercentageColor = (percentage) => {

    let textColor;
  
    switch (true) {
      case percentage === 100:
        textColor = '#fff'
        break;
      case percentage >= 75 && percentage <= 99:
        textColor = '#000'
        break;
      case percentage >= 63 && percentage <= 74:
        textColor = '#000'
        break;
      case percentage >= 50 && percentage <= 62:
        textColor = '#000'
        break;
      case percentage >= 25 && percentage <= 49:
        textColor = '#fff'
        break;
      case percentage >= 1 && percentage <= 24:
        textColor = '#fff'
        break;
      default:
        textColor = '#fff'
    }
  
    return textColor;
  };
  