import IntroPage from "../components/IntroPage";
import Questions from "../components/Questions";
import ResultPage from "../components/ResultPage";
import Submission from "../components/Submission";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function Home() {
 
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IntroPage />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/submission" element={<Submission />} />
          <Route path="/result" element={<ResultPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
