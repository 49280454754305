// Vatility message start from here 
export const vitality_Header_100='Indication shows an excellent value.'
export const vitality_Header_99_75='The indicative value is good.'
export const vitality_Header_74_63='Your indicative value is in the okay range with some items to be monitored.'
export const vitality_Header_62_50='Your value indicates some attention for improvements is needed in certain areas to reduce potential risks.'
export const vitality_Header_49_25='Your overall indicative value is in the lower range, which inidcates some early risks factors to care for.'
export const vitality_Header_24_1='Your overall indication is in the very low range, which shows potential risks.'

export const vitality_Suggestion_100='Keep it up.'
export const vitality_Suggestion_99_75='Even with a good indicative value,  you can likely think of improvements to excel even better.'
export const vitality_Suggestion_74_63='It would be beneficial to identify further actions to take to conquer daily challenges.'
export const vitality_Suggestion_62_50='By becoming more aware of areas you scored low, this will help you to better understand where to start and what to do.'
export const vitality_Suggestion_49_25='You are probably aware that you need to make constructive changes in several areas.'
export const vitality_Suggestion_24_1='The best recommendation is to identify more the potential risk factors. Enrol in the Orchestrating Healthy Performance program as soon as possible.'

export const vitality_Recommendation_100='Continue what you\'re doing.'
export const vitality_Recommendation_99_75='Work on one item at a time that you feel needs improvement and re-take the Self-Test again in a few weeks.'
export const vitality_Recommendation_74_63='You can benefit from completing the Self-Test again and enrol in the Orchestrating Healthy Performance program to better identify hidden causes before they become an issue.'
export const vitality_Recommendation_62_50='To deepen your awareness, enrol in the Orchestrating Healthy Performance program and complete the full Personal Dynamic Inventory to better understand your hidden needs.'
export const vitality_Recommendation_49_25='Recommendation: enrol in the Orchestrating Healthy Performance program, you will learn new skills to better master your energy needs and living style.'
export const vitality_Recommendation_24_1='By completing the full Personal Dynamic Inventory you will have a better oveview on risk factors and can have a construtive discussion with a health professional for more in-person actions.'

// Burnout message start from here 

export const Burnout_Header_100='Indication show no Over-Effort-Syndrome trends.'
export const Burnout_Header_99_75='The indicative value is at a good level with no Over-Effort-Syndrome risks.'
export const Burnout_Header_74_63='Your indicative value for Over-Effort-Syndrome risk is at satisfactory level.'
export const Burnout_Header_62_50='Your indicative value shows a number of work-life challanges that need to be monitored as they can provoke increased risks of Over-Effort-Syndrome.'
export const Burnout_Header_49_25='Your overall value inidcates several Over-Effort-Syndrome risk factors and challenges that need your full attention.'
export const Burnout_Header_24_1='Your overall indicative value is in the very low range, which shows a high potential Over-Effort-Syndrome (burnout) risks.  '

export const Burnout_Suggestion_100='Keep it up.'
export const Burnout_Suggestion_99_75='Even with a good indicative value,  you can likely think of improvements to excel even better.'
export const Burnout_Suggestion_74_63='It\'s beneficial to identify further what actions are needed to be taken to conquer daily changes in specific segments.'
export const Burnout_Suggestion_62_50='Becoming more aware of areas you scored low in will help you to better understand where to start and what to do.'
export const Burnout_Suggestion_49_25='You are probably aware that you need to make constructive changes in several areas.'
export const Burnout_Suggestion_24_1='The best recommendation is to identify further the potential risks factors. Enroll in the Orchestrating Healthy Performance program as soon as possible.'

export const Burnout_Recommendation_100='Bravo. Continue what you\'re doing.'
export const Burnout_Recommendation_99_75='Work on items at a time that you feel you need improvements and re-take the Self-Test.'
export const Burnout_Recommendation_74_63='You can benefit from completing the Self-Test again and also enrol in the Orchestrating Healthy Performance program to identify hidden causes better.'
export const Burnout_Recommendation_62_50='To deepen your awareness, enrol in the Orchestrating Healthy Performance program to better understand your hidden needs.'
export const Burnout_Recommendation_49_25='Recommendation: enrol in the Orchestrating Healthy Performance program to better understand what to do reduce risks.'
export const Burnout_Recommendation_24_1='By completing the full Personal Dynamic Inventory, you will have a better overview on risk factors and can have a constructive  discussion with a health professional for more in-person actions.'

// Apnea message start from here 

export const Apnea_Header_100='Indication is showing you are doing very well.'
export const Apnea_Header_99_75='The indicative value is within good and there is no direct sign of sleep apnea.'
export const Apnea_Header_74_63='Your indicative value is in the okay range with some items to be monitored. Try to practice belly breathing.'
export const Apnea_Header_62_50='Your indicative value shows some attention is needed for improvements of sleep apnea risks.'
export const Apnea_Header_49_25='Your indicative value is in the lower range, which indicates that a full apnea questionnaire should be taken.'
export const Apnea_Header_24_1='Your indicative value is in the very low range, which shows potential risks of sleep apnea. It’s recommended you take a full apnea questionnaire. '

export const Apnea_Suggestion_100='Keep it up.'
export const Apnea_Suggestion_99_75='Even with a good indicative value, you can likely think of breathing training to practice. '
export const Apnea_Suggestion_74_63='It\'s beneficial to further identify what actions are needed to be taken to conquer daily changes in specific segments.'
export const Apnea_Suggestion_62_50='Becoming more aware of areas you scored low will help you to better understand where to start and what to do.'
export const Apnea_Suggestion_49_25='You are probably aware that you need to make constructive changes in certain areas.'
export const Apnea_Suggestion_24_1='The best recommendation is to identify further the potential risks factors. Enrol in the Orchestrating Healthy Performance program as soon as possible.'

export const Apnea_Recommendation_100='Continue what you\'re doing.'
export const Apnea_Recommendation_99_75='Work on one item at a time that you feel needs improvements and re-take the Self-Test again.'
export const Apnea_Recommendation_74_63='You can benefit from completing the Self-Test again to identify hidden causes better.'
export const Apnea_Recommendation_62_50='To deepen your awareness, complete the full Apnea Inventory'
export const Apnea_Recommendation_49_25='Recommendation: enrol in the Orchestrating Healthy Performance program'
export const Apnea_Recommendation_24_1='By completing the full Apnea Inventory, you will have a better overview on risk factors and can have a constructive  discussion with a health professional for more in-person actions.'
