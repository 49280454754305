import { Link, useNavigate } from "react-router-dom";
import homeImage from "../images/home-image.png";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function IntroPage() {
  const [clickTerms, setclickTerms] = useState(false);
  const [show, setShow] = useState(false);
  const textRef = useRef();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clickOnTerms = () => {
    setclickTerms(!clickTerms);
  };

  const onQuestions = () => {
    navigate("/questions");
    window.parent.postMessage("scrollToTop", "*");
  };

  useEffect(() => {
    if (clickTerms) {
      handleShow();
    } else {
      handleClose();
    }
  }, [clickTerms]);

  return (
    <>
      <Modal centered show={show} onHide={handleClose} backdrop="static" className="modelTop">
        <Modal.Header>
          <Modal.Title>Terms ans Condition</Modal.Title>
          <Button
            className="closebtn"
            variant="secondary"
            onClick={handleClose}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          This ARA Self-Test uses an informal approach to test if you have
          indications of Over-Effort-Syndrome risks - Early Burnout? While it
          may be intuitively useful, it has not been validated through
          controlled scientific tests and must therefore not be used as a
          diagnostic technique.<br></br>
          <br></br>
          Please, therefore, interpret the results with common sense. You gives
          ARA.Health allowance to send limited number of mails regarding related
          program offerings.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bottom-closebtn"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container">
        <div className="row">
          <div className="col-10 m-auto">
            <img src={homeImage} className="img-fluid" alt="" />
            <p className="pt-4 text">
              <span className="blue-text font-weight-bold">ARA Self-Test</span>
              &nbsp; is a tool to quick check yourself for Over-Effort-Syndrome
              (early burnout). It takes less then 5 min to complete. Then to
              receive a personalized feedback click "Request Feedback" button
              and enter your e-mail underneath.
              <br />
              <br />
              Over-Effort-Syndrome (early burnout) is a factor that can affect
              your vitality, work performance and sleep apnea as it relates to
              prolonged fatigue and exhaustion. This Self-Test helps you to give
              you a first feel for whether you are at risk of
              Over-Effort-Syndrome (as state of early burnout) or not. <br />{" "}
              <br />
              For each question, click the button that most applies to you. 
            </p>
            <p className="text">
              This &nbsp;
              <span className="blue-text font-weight-bold">ARA Self-Test</span>
              &nbsp; uses an informal approach to test if you have indications
              of Over-Effort-Syndrome risks? While it may be intuitively useful,
              it has not been validated through controlled scientific tests and
              must therefore not be used as a diagnostic technique. Please,
              therefore, interpret the results with common sense. You gives
              ARA.Health allowance to send limited number of mails regarding
              related program offerings.
            </p>
            <p className="text">
              Client click on start and completed the questionnaire{" "}
            </p>
            <div className="row pt-3 intro-bottom">
              <div className="col-sm-6 self-test">
                <button
                  onClick={() => onQuestions()}
                  disabled={clickTerms ? false : true}
                  type="button"
                  className="btn bluebtn rounded-pill "
                >
                  <span className="pl-4 pr-4">Start the Self Test </span>
                </button>
              </div>
              <div className="col-sm-6 ">
                <form>
                  <div className="myTest custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() => clickOnTerms()}
                      className="custom-control-label"
                      for="customCheck1"
                    >
                      Terms & Conditions
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
